.span-domain {
    color: var(--primary-blue);
    font-weight: 800;
    letter-spacing: 10px;
}

.p-domain {
    text-align: center;
    padding: 0 5vw 0 5vw;
}
