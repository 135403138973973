.title-description {
    margin-top: 90px;
    text-align: center;
    padding: 0 1em 0 1em;
}

.title-description h3 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0;
}

.title-description p {
    font-size: 20px;
    margin-top: 10px;
}

.title-description hr {
    width: 100px;
    border: 3px solid var(--primary-blue);
    border-radius: 10px;
    margin: 20px auto;
    box-shadow: 1px 1px 2px;
}
