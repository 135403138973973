.texts {
    padding: 0 5vw 0 5vw;
    margin-top: 0;
    background-image: url("/public/images/background/small-ball-blue-1.svg");
    background-repeat: no-repeat;
    background-position-y: 0%;
    background-position-x: 66%;
}

h2 {
    font-size: 48px;
    line-height: 40px;
    margin-bottom: 0;
}

.titles {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-blue);
    text-shadow: 1px 1px #789dde;
}

p {
    font-size: 1.2em;
}

@media screen and (min-width: 720px) {
    .texts {
        margin-top: 9em;
    }

    h2 {
        font-size: 70px;
        line-height: 60px;
    }
}
